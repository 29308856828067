import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import './App.scss'
import { fetchPaymentURL } from './lib'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useHistory,
} from 'react-router-dom'

const REDIRECT_TIMEOUT = 4000

interface FormProps {
  setRedirectUrl: (url: string) => void
}
const Form = ({ setRedirectUrl }: FormProps) => {
  let { ref } = useParams<{ ref: string }>()
  const history = useHistory()
  const [invoiceRef, seInvoiceRef] = useState(ref || '')
  const [isFetchingPaymentURL, setIsFetchingPaymentURL] = useState(false)
  const [error, setError] = useState('')

  const handleSubmit = async () => {
    setIsFetchingPaymentURL(true)
    setError('')
    try {
      const url = await fetchPaymentURL(invoiceRef)
      setIsFetchingPaymentURL(false)
      if (url) {
        setRedirectUrl(url)
        history.push(`/redirect/${invoiceRef}`)
      }
    } catch (err) {
      setIsFetchingPaymentURL(false)
      setError(err.message || 'Something went wrong, try again')
    }
  }

  return (
    <>
      <div className="info">
        <h1>EFKT Pay</h1>
        <p>
          Betal din EFKT-faktura på nett! Med kort, Vipps eller Afterpay
          delbetaling.
        </p>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        <label htmlFor="invoice-ref">
          Bruk referanse du fikk i SMS eller faktura
        </label>
        <input
          id="invoice-ref"
          type="text"
          value={invoiceRef}
          onChange={(e) => {
            seInvoiceRef(e.target.value)
          }}
          placeholder="Referanse"
        />

        <button
          disabled={!invoiceRef || isFetchingPaymentURL}
          className="button"
          type="submit"
        >
          {isFetchingPaymentURL ? (
            <span>Sjekker...</span>
          ) : (
            <span>Gå til betaling</span>
          )}
        </button>
      </form>
      {error && (
        <div className="errors">
          <h2>Noe gikk galt</h2>
          <p>{error}</p>
          <p>
            Trenger du fortsatt hjelp, ta kontakt med EFKT kundesenter på e-post{' '}
            <a href="mailto:kundeservice@efkt.co">kundeservice@efkt.co</a> eller
            telefon <a href="tel:210 80 500">210 80 500</a> (mandag - fredag
            0830 til 1630)
          </p>
        </div>
      )}
    </>
  )
}

interface RedirecterProps {
  redirectUrl: string
}
const Redirecter = ({ redirectUrl }: RedirecterProps) => {
  useEffect(() => {
    if (redirectUrl) {
      let timeout = setTimeout(() => {
        window.location.href = redirectUrl
      }, REDIRECT_TIMEOUT)

      return () => clearTimeout(timeout)
    }
  }, [redirectUrl])

  if (!redirectUrl) {
    return (
      <>
        <header>
          <h1>Noe gikk galt</h1>
        </header>
        <div className="errors">
          <p>Error: Ingen redirectURL</p>
        </div>
      </>
    )
  }

  return (
    <motion.div
      className="redirect-notice"
      style={{ y: -40, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <header>
        <h1>Videresender deg til betalingssiden</h1>
      </header>
      <p>
        Tusen takk for at du bruker nettbetaling! Du vil straks bli videresendt
        til betalingssiden.
      </p>
      <p>
        Hvis du ikke blir videresendt, klikk på denne linken:{' '}
        <a href={redirectUrl}>{redirectUrl}</a>
      </p>
    </motion.div>
  )
}

const App = () => {
  const [redirectUrl, setRedirectUrl] = useState('')
  return (
    <Router>
      <div className="app">
        <div className="main-wrapper">
          <header>
            <img src="/logo.svg" className="logo" alt="EFKT" />
          </header>
          <Switch>
            <Route
              path="/redirect/:ref"
              children={<Redirecter redirectUrl={redirectUrl} />}
            />
            <Route
              path={['/p/:ref', '/']}
              children={<Form setRedirectUrl={setRedirectUrl} />}
            />
          </Switch>
        </div>
        <footer>
          © EFKT AS - Les vår{' '}
          <a href="https://efkt.com/datasikkerhed">personvernpolicy</a>
        </footer>
      </div>
    </Router>
  )
}

export default App
