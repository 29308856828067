export const fetchPaymentURL = async (invoiceRef: string) => {
  try {
    const response = await fetch(
      `https://iweb-core-ecommerce-prod.herokuapp.com/public-api/payments/${invoiceRef}/payment-link`
    )

    if (response.status === 404) {
      throw new Error(
        `Ingen faktura med referanse ID ${invoiceRef}. Er du sikker på at denne er riktig?`
      )
    }

    const json = await response.json()
    return json.data
  } catch (_err) {
    throw new Error(`Kunne ikke hente ${invoiceRef}. Prøv på nytt.`)
  }
}
